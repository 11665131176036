import React from 'react';
import DOMPurify from 'dompurify';

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8005/backend/customsoftware/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: title, text, image
function ElementPanelContain(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="col-12 d-block d-md-none">
                <div className="panel-container-mobile">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-image-contain" style={{backgroundImage: 'url(/image/'+props.image+'.jpg)'}}></div>
                </div>
            </div>

            { /* MD, LG, XL, XXL */ }
            <div className="col-6 d-none d-md-block">
                <div className="panel-container-desktop">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-image-contain" style={{backgroundImage: 'url(/image/'+props.image+'.jpg)'}}></div>
                </div>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="element-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="element-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH2(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="element-title-h2-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="element-title-h2-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

export { ElementDebugResponsive, ElementPanelContain, ElementTitleH1, ElementTitleH2 };